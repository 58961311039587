<template>
  <div class="mb-5">

    <router-link :to="{name: 'privacy-policy.list'}">See other policies</router-link>
    <div class="text-center">
      <h2>Livestream <br>(Iframe)</h2>
    </div>

    <hr />
    <div>
      <p>Effective Date: 14/03/2024</p>
    </div>
    <hr />

    <div>
      <h3>Introduction</h3>
      <p>This Privacy Policy describes how OpenMasjid.io ("we," "us," or "our") collects, uses, and discloses your information in connection with your use of our application, OpenMasjid Livestream (the "Iframe").</p>
    </div>
    <hr />

    <div>
      <h3>Information We Collect</h3>
      <p>We collect the following information through the Iframe, depending on the social login provider you choose:</p>
      <ul>
        <li><strong>Social Login Information:</strong> When you sign in using social login services like Google Sign In or Apple Sign In, we may collect your name and email address, depending on the social login provider and your privacy settings on that platform. In some cases, such as Apple Sign In with "Hide My Email," we may only receive a unique, non-identifiable email address.</li>
      </ul>
    </div>
    <hr />

    <div>
      <h3>Use of Information</h3>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li><strong>Authentication:</strong> We use your social login information to authenticate you and allow you to access the Iframe.</li>
        <li><strong>User Experience:</strong> We use your name (if provided) to personalize your user experience within the Iframe.</li>
      </ul>
    </div>
    <hr />

    <div>
      <h3>Information Sharing</h3>
      <p>We do not share your information with any third parties, except for the social login providers you choose. The social login providers may have their own privacy policies that describe how they collect and use your information. We recommend reviewing their privacy policies before using their services.</p>
    </div>
    <hr />

    <div>
      <h3>Cookie Policy</h3>

      <h4>What are Cookies?</h4>
      <p>Cookies are small text files that websites and online services store on your device. They are used to remember information about you and your preferences, so you don't have to keep entering them whenever you visit a site or use an online service.</p>

      <h4>What Cookies Do We Use?</h4>
      <p>The Iframe uses a session cookie. This cookie is temporary and is automatically deleted when you close your browser. The cookie does not contain any personally identifiable information (PII).</p>

      <h4>How to Control Cookies</h4>
      <p>Most web browsers allow you to control cookies through their settings. You can usually choose to block all cookies, delete existing cookies, or be notified before a cookie is set. Here are links to instructions for popular browsers:</p>
      <ul>
        <li>For the Chrome web browser, please visit this page from Google: <a href="https://support.google.com/accounts/answer/32050">https://support.google.com/accounts/answer/32050</a></li>
        <li>For the Internet Explorer web browser, please visit this page from Microsoft: <a href="https://support.microsoft.com/kb/278835">https://support.microsoft.com/kb/278835</a></li>
        <li>For the Firefox web browser, please visit this page from Mozilla: <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></li>
        <li>For the Safari web browser, please visit this page from Apple: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></li>
      </ul>

      <p>Please be aware that you can choose to block cookies that we may deliver to your device through settings on your web-browser; however, in doing so you may not be able to access or utilise all aspects of this Iframe.</p>
    </div>
    <hr />

    <div>
      <h3>Data Retention</h3>
      <p>We will retain your information for as long as your account is active or as needed to provide you with the services offered by the Iframe. We will also retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
      <p>The session cookie will be automatically deleted when you close your browser window or tab where the Iframe is used.</p>
    </div>
    <hr />

    <div>
      <h3>Your Choices</h3>
      <p>You can manage your social login settings directly through the social login provider's platform. You can also delete your App account by contacting us at <a href="mailto:talha@arcticprism.com">talha@arcticprism.com.</a></p>
      <p>You can control cookies through your web browser settings as described above.</p>
    </div>
    <hr />

    <div>
      <h3>Security</h3>
      <p>We take reasonable steps to protect the information you provide from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission or electronic storage is ever completely secure.</p>
    </div>
    <hr />

    <div>
      <h3>Changes to this Privacy Policy</h3>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on <a href="https://openmasjid.io">https://OpenMasjid.io</a> (if applicable) or within the Iframe.</p>
    </div>
    <hr />

    <div>
      <h3>Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, please contact us at:<br /> <a href="mailto:talha@arcticprism.com">talha@arcticprism.com.</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LivestreamIframePrivacyPolicy"
}
</script>

<style scoped>

</style>