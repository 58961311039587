<template>
  <footer class="footer">
    <div class="container">
<!--      <div class="row row-grid align-items-center mb-5">-->
<!--        <div class="col-lg-6">-->
<!--          <h3 class="text-primary font-weight-light mb-2">-->
<!--            Thank you for supporting us!-->
<!--          </h3>-->
<!--          <h4 class="mb-0 font-weight-light">-->
<!--            Let's get in touch on any of these platforms.-->
<!--          </h4>-->
<!--        </div>-->
<!--        <div class="col-lg-6 text-lg-center btn-wrapper">-->
<!--          <button-->
<!--            target="_blank"-->
<!--            href="https://twitter.com/"-->
<!--            rel="nofollow"-->
<!--            class="btn btn-icon-only btn-twitter rounded-circle"-->
<!--            data-toggle="tooltip"-->
<!--            data-original-title="Follow us"-->
<!--          >-->
<!--            <span class="btn-inner&#45;&#45;icon"><i class="fa fa-twitter"></i></span>-->
<!--          </button>-->
<!--          <button-->
<!--            target="_blank"-->
<!--            href="https://www.facebook.com//"-->
<!--            rel="nofollow"-->
<!--            class="btn-icon-only rounded-circle btn btn-facebook"-->
<!--            data-toggle="tooltip"-->
<!--            data-original-title="Like us"-->
<!--          >-->
<!--            <span class="btn-inner&#45;&#45;icon"><i class="fa fa-facebook"></i></span>-->
<!--          </button>-->
<!--          <button-->
<!--            target="_blank"-->
<!--            href="https://dribbble.com/"-->
<!--            rel="nofollow"-->
<!--            class="btn btn-icon-only btn-dribbble rounded-circle"-->
<!--            data-toggle="tooltip"-->
<!--            data-original-title="Follow us"-->
<!--          >-->
<!--            <span class="btn-inner&#45;&#45;icon"><i class="fa fa-dribbble"></i></span>-->
<!--          </button>-->
<!--          <button-->
<!--            target="_blank"-->
<!--            href="https://github.com/"-->
<!--            rel="nofollow"-->
<!--            class="btn btn-icon-only btn-github rounded-circle"-->
<!--            data-toggle="tooltip"-->
<!--            data-original-title="Star on Github"-->
<!--          >-->
<!--            <span class="btn-inner&#45;&#45;icon"><i class="fa fa-github"></i></span>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-6">
          <div class="copyright">
            &copy; {{ year }}
            <a href="javascript:void();" target="_blank">OpenMasjid.io</a>
          </div>
        </div>
        <div class="col-md-6">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <router-link to="contact-us" class="nav-link">
                Contact us
              </router-link
              >
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'privacy-policy' }" class="nav-link"
                >Privacy Policies
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'terms-of-service' }" class="nav-link">
                Terms of Service
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
