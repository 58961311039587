<template>
  <div>
    <h5>Products:</h5>
    <ul>
      <li><router-link :to="{name: 'terms-of-service.livestream'}">Livestream</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ListProducts"
}
</script>

<style scoped>

</style>