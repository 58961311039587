var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5"},[_c('router-link',{attrs:{"to":{name: 'terms-of-service.list'}}},[_vm._v("See other terms")]),_vm._m(0),_c('hr'),_vm._m(1),_c('hr'),_vm._m(2),_c('hr'),_vm._m(3),_c('hr'),_vm._m(4),_c('hr'),_vm._m(5),_c('hr'),_vm._m(6),_c('hr'),_vm._m(7),_c('hr'),_vm._m(8),_c('hr'),_vm._m(9),_c('hr'),_vm._m(10),_c('hr'),_vm._m(11),_c('hr'),_vm._m(12),_c('hr'),_vm._m(13)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h2',[_vm._v("Livestream "),_c('br'),_vm._v("(iOS/Android App)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("Effective Date: 14/03/2024")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("1. Introduction")]),_c('p',[_vm._v("Welcome to OpenMasjid Livestream (\"App\"). These Terms of Service (\"Terms\") govern your use of the App, which includes both the native mobile application and the iframe version (\"Iframe\") that can be embedded on external websites. The App is owned and operated by OpenMasjid.io (\"we,\" \"us,\" or \"our\"). By accessing or using the App, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access or use the App.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("2. Use of the App")]),_c('p',[_c('strong',[_vm._v("2.1")]),_vm._v(" You must be at least 13 years old to use the App. By using the App, you represent and warrant that you are at least 13 years old and of legal capacity to enter into a binding agreement.")]),_c('p',[_c('strong',[_vm._v("2.2")]),_vm._v(" You are responsible for maintaining the confidentiality of your account information and password. You are also responsible for all activity that occurs under your account.")]),_c('p',[_c('strong',[_vm._v("2.3")]),_vm._v(" You agree to use the App in accordance with all applicable laws and regulations.")]),_c('p',[_c('strong',[_vm._v("2.4")]),_vm._v(" You agree not to use the App for any purpose that is unlawful or unauthorized. This includes, but is not limited to:")]),_c('ul',[_c('li',[_vm._v("Infringing on the intellectual property rights of others.")]),_c('li',[_vm._v("Transmitting any harmful or malicious code, such as viruses or worms.")]),_c('li',[_vm._v("Interfering with or disrupting the App or any servers or networks connected to the App.")]),_c('li',[_vm._v("Impersonating any person or entity.")]),_c('li',[_vm._v("Collecting or storing personal information about other users without their express consent.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("3. Intellectual Property")]),_c('p',[_vm._v("The App and all content and materials included in the App (including, but not limited to, text, graphics, logos, images, and software) are the property of OpenMasjid.io or its licensors and are protected by copyright, trademark, and other intellectual property laws.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("4. Disclaimers")]),_c('p',[_vm._v("THE APP IS PROVIDED \"AS IS\" AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE APP WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE APP IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("5. Iframe Data Collection")]),_c('p',[_vm._v("The Iframe may use cookies to maintain session state and improve user experience. These cookies are temporary and do not contain any personally identifiable information (PII).")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("6. Limitation of Liability")]),_c('p',[_vm._v("WE WILL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF THE APP, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("7. Termination")]),_c('p',[_vm._v("We may terminate your access to the App at any time for any reason, with or without notice.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("8. Governing Law")]),_c('p',[_vm._v("These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("9. Entire Agreement")]),_c('p',[_vm._v("These Terms constitute the entire agreement between you and us regarding your use of the App.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("10. Severability")]),_c('p',[_vm._v("If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("11. Changes to these Terms")]),_c('p',[_vm._v("We may update these Terms at any time. We will notify you of any changes by posting the new Terms on the App. You are advised to consult these Terms regularly for any changes. By continuing to use the App after the revised Terms are posted, you agree to be bound by the new Terms.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Contact Us")]),_c('p',[_vm._v("If you have any questions about this Privacy Policy, please contact us at:"),_c('br'),_vm._v(" "),_c('a',{attrs:{"href":"mailto:talha@arcticprism.com"}},[_vm._v("talha@arcticprism.com.")])])])
}]

export { render, staticRenderFns }