<template>
  <div class="wrapper">
    <header class="header-4 skew-separator">
      <div class="header-wrapper">
        <div class="page-header header-video">
          <div class="overlay bg-gradient-primary"></div>
          <div class="container text-center">
            <div class="row">
              <div class="col-lg-7 mx-auto">
                <h1 class="text-default">
                  Empowering Mosques, Strengthening Communities
                </h1>
                <h1 class="display-4 text-primary">
                  Software for the sake of Allah (SWT)
                </h1>
                <base-button
                  icon="ni ni-chat-round"
                  type="default"
                  class="mt-3 mb-sm-0"
                  @click="$router.push({ name: 'contact-us' })"
                  >Talk to us</base-button
                >
                <div class="text-center my-3">
                  <a class="text-default" href="#help-with-release"><i class="ni ni-mobile-button my-1"></i> &nbsp;<u>Help us release our Livestream app for FREE</u></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- First Feature Section -->
    <Feature></Feature>
    <!-- Second Feature Section -->
    <Feature1></Feature1>
    <Feature2></Feature2>
  </div>
</template>
<script>
import Feature from "./sections/Features/Feature6.vue";
import Feature1 from "./sections/Features/Feature1.vue";
import Feature2 from "./sections/Features/Feature2.vue";

export default {
  components: {
    Feature,
    Feature1,
    Feature2
  },
};
</script>
<style></style>
