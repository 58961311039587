import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import LandingPage from "./views/LandingPage.vue";
import Error from "./views/Error.vue";
import ContactUs from "@/views/ContactUs";
import PrivacyPolicies from "@/views/PrivacyPolicies";
import LivestreamAppPrivacyPolicy from "@/views/privacy_policies/LivestreamAppPrivacyPolicy";
import ListProducts from "@/views/privacy_policies/ListProducts";
import LivestreamIframePrivacyPolicy from "@/views/privacy_policies/LivestreamIframePrivacyPolicy";
import LivestreamTermsOfService from "@/views/terms_of_services/LivestreamTermsOfService";
import ListProductsForTermsOfServices from "@/views/terms_of_services/ListProducts";
import TermsOfServices from "@/views/TermsOfServices";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      components: {
        header: AppHeader,
        default: LandingPage,
        footer: AppFooter,
      },
    },
    {
      path: "/contact-us",
      name: "contact-us",
      components: {
        header: AppHeader,
        default: ContactUs,
        footer: AppFooter,
      },
    },
    {
      path: "/privacy-policies",
      name: "privacy-policy",
      redirect: "/privacy-policies",
      components: {
        header: AppHeader,
        default: PrivacyPolicies,
        footer: AppFooter,
      },
      children: [
        {
          path: '/',
          name: 'privacy-policy.list',
          component: ListProducts
        },
        {
          path: "livestream-app",
          name: "privacy-policy.livestream-app",
          component: LivestreamAppPrivacyPolicy,
        },
        {
          path: "livestream-iframe",
          name: "privacy-policy.livestream-iframe",
          component: LivestreamIframePrivacyPolicy,
        },
      ],
    },
    {
      path: "/terms-of-services",
      name: "terms-of-service",
      redirect: "/terms-of-services",
      components: {
        header: AppHeader,
        default: TermsOfServices,
        footer: AppFooter,
      },
      children: [
        {
          path: "/",
          name: "terms-of-service.list",
          component: ListProductsForTermsOfServices
        },
        {
          path: "livestream",
          name: "terms-of-service.livestream",
          component: LivestreamTermsOfService,
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      name: "error",
      components: {
        header: AppHeader,
        default: Error,
      },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
