<template>
  <div class="wrapper">
    <div class="main">
      <div v-if="loading" class="container-fluid text-center">
        <div class="row mt-5 mb-4 pt-5">
          <div class="col-md-8 ml-auto mr-auto text-center mt-5 h-100vh">
            <h1>Sending <br><spinner></spinner></h1>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="!submitted" class="container-fluid">
          <div class="row mt-5 mb-4 pt-5">
            <div class="col-md-8 ml-auto mr-auto text-center mt-5">
              <h1 class="title">Got a <b>question?</b></h1>
              <br />
              <h4 class="desc">
                Whether you have questions or you would just like to say Salaam,
                contact us.
              </h4>
              <br />
            </div>
          </div>
          <div class="row my-5">
            <div class="col-md-6 mx-auto">
              <form
                role="form"
                class="card bg-secondary p-3"
                id="contact-form"
                @submit.prevent="submitForm"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>First name*</label>
                        <base-input
                          v-model="form.first_name"
                          alternative
                          placeholder="First Name..."
                          addonLeftIcon="ni ni-circle-08"
                          required
                        ></base-input>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Last name*</label>
                        <base-input
                          v-model="form.last_name"
                          alternative
                          placeholder="Last Name..."
                          addonLeftIcon="ni ni-tag"
                          required
                        ></base-input>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Email address*</label>
                    <base-input
                      v-model="form.email"
                      alternative
                      placeholder="Email Here..."
                      addonLeftIcon="ni ni-email-83"
                      required
                    ></base-input>
                  </div>
                  <div class="form-group">
                    <label>Your message*</label>
                    <textarea
                      v-model="form.message"
                      name="message"
                      class="form-control form-control-alternative"
                      id="message"
                      rows="6"
                      :required="true"
                    ></textarea>
                  </div>
                  <div class="row">
                    <div class="col-md-6 ml-auto">
                      <base-button
                        nativeType="submit"
                        type="primary"
                        class="pull-right"
                        >Send Message</base-button
                      >
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div v-else class="container-fluid h-100vh">
          <div class="row mt-5 mb-4 pt-5">
            <div class="col-md-8 ml-auto mr-auto text-center mt-5">
              <h1 class="title">{{ serverMessage }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Spinner from "@/components/Spinner";

export const apiClient = axios.create({
  baseURL: "https://api.openmasjid.io/api/",
  withCredentials: true, // required to handle the CSRF token
  withXSRFToken: true,
});

export default {
  components: { Spinner },
  bodyClass: "contact-page",
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        message: "",
      },
      submitted: false,
      serverMessage: "",
      loading: false,
    };
  },
  methods: {
    async submitForm() {
      try {
        this.loading = true
        let { data } = await apiClient.post("contact-us", {
          ...this.form,
        });
        this.serverMessage = data.message
      } catch (e) {
        console.log(e)
        this.serverMessage = "Oops! Something went wrong.";
      } finally {
        this.loading = false
        this.submitted = true
      }
    },
  },
};
</script>
<style></style>
