<template>
  <div class="mb-5">
    <router-link :to="{name: 'privacy-policy.list'}">See other policies</router-link>
    <div class="text-center">
      <h2>Livestream <br>(iOS/Android App)</h2>
    </div>

    <hr />
    <div>
      <p>Effective Date: 14/03/2024</p>
    </div>
    <hr />

    <div>
      <h3>Introduction</h3>
      <p>This Privacy Policy describes how OpenMasjid.io ("we," "us," or "our") collects, uses, and discloses your information in connection with your use of our application, OpenMasjid Livestream (the "App").</p>
    </div>
    <hr />

    <div>
      <h3>Information We Collect</h3>
      <p>We collect the following information through the App:</p>
      <ul>
        <li><strong>Social Login Information:</strong> When you sign in using social login services like Google Sign In or Apple Sign In, we may collect your name and email address, depending on the social login provider and your privacy settings on that platform. In some cases, such as Apple Sign In with "Hide My Email," we may only receive a unique, non-identifiable email address.</li>
        <li><strong>Device ID:</strong> We generate a random, unique device ID that is stored on your device.</li>
        <li><strong>API Token:</strong> We create an API token on our server specifically for you.</li>
      </ul>
    </div>
    <hr />

    <div>
      <h3>Use of Information</h3>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li><strong>Authentication:</strong> We use your social login information to authenticate you and allow you to access the App.</li>
        <li><strong>User Experience:</strong> We use your name (if provided) to personalize your user experience within the App.</li>
        <li><strong>Device Identification:</strong> We use the device ID to uniquely identify you for the purpose of generating tokens to join live broadcasts (audio only).</li>
        <li><strong>API Token:</strong>  We use the API token to authenticate your device with our server and enable you to join live broadcasts.</li>
      </ul>
    </div>
    <hr />

    <div>
      <h3>Information Sharing</h3>
      <p>We do not share your information with any third parties, except for the social login providers you choose. The social login providers may have their own privacy policies that describe how they collect and use your information. We recommend reviewing their privacy policies before using their services.</p>
    </div>
    <hr />

    <div>
      <h3>Data Retention</h3>
      <p>We will retain your information for as long as your account is active or as needed to provide you with the services offered by the App. We will also retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
    </div>
    <hr />

    <div>
      <h3>Your Choices</h3>
      <p>You can manage your social login settings directly through the social login provider's platform. You can also delete your App account by contacting us at <a href="mailto:talha@arcticprism.com">talha@arcticprism.com.</a></p>
    </div>
    <hr />

    <div>
      <h3>Security</h3>
      <p>We take reasonable steps to protect the information you provide from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission or electronic storage is ever completely secure.</p>
    </div>
    <hr />

    <div>
      <h3>Changes to this Privacy Policy</h3>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the App.</p>
    </div>
    <hr />

    <div>
      <h3>Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, please contact us at:<br /> <a href="mailto:talha@arcticprism.com">talha@arcticprism.com.</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LivestreamPrivacyPolicy"
}
</script>

<style scoped>

</style>