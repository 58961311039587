<template>
  <header class="header-global">
    <base-nav
      class="navbar-main headroom"
      id="navbar-main"
      :type="navbarType ? navbarType : ''"
      effect="dark"
      expand
    >
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img src="/img/brand/openmasjid-logo-with-text.svg" alt="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="javascript:void(0)">
            <img src="/img/brand/openmasjid-logo-with-text.svg" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center mx-auto">
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-lg">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-tablet-button d-lg-none"></i>
            <span class="nav-link-inner--text text-default">Products</span>
          </a>
          <router-link to="/landing"></router-link>

          <div class="dropdown-menu-inner">
            <a
              class="dropdown-item"
              href="#"
            >
              <div class="d-flex">
                <img class="icon my-auto" src="/img/brand/livestream-logo-white.svg" />
                <div class="my-auto">Livestream</div>
              </div>
            </a>
          </div>
        </base-dropdown>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import BaseDropdown from "@/components/BaseDropdown";
// import Headroom from "headroom.js";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
  props: {
    navbarType: String,
  },
};
</script>
<style>
.navbar-main.headroom {
  z-index: 9999;
}
</style>
