<template>
  <div class="wrapper">
    <div class="main">
      <div class="container">
        <div class="row mt-5 mb-4 pt-5">
          <div class="col-md-8 ml-auto mr-auto text-center mt-5">
            <h1 class="title">Privacy Policies</h1>
            <br />
          </div>
        </div>
        <fade-transition origin="center" mode="out-in" :duration="250">
          <router-view />
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style></style>
