<template>
  <div>
    <h5>Products:</h5>
    <ul>
      <li><router-link :to="{name: 'privacy-policy.livestream-app'}">Livestream (iOS/Android App)</router-link></li>
      <li><router-link :to="{name: 'privacy-policy.livestream-iframe'}">Livestream (Iframe)</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ListProducts"
}
</script>

<style scoped>

</style>